import * as echarts from "echarts";
import { defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      echarts: echarts,
    }
  }
})

