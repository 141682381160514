import { defineNuxtPlugin } from '#imports';

import { useRouter } from 'vue-router';

export default defineNuxtPlugin(({ $router }) => {
  const router = useRouter();

  router.addRoute({
    name: 'projects-detail-id',
    path: '/projects/detail/:id?',
    component: () => import('@/pages/projects/detail/[id].vue'),
  });
  router.addRoute({
    name: 'investors-detail-id',
    path: '/investors/detail/:id?',
    component: () => import('@/pages/investors/detail/[id].vue'),
  });
  // router.addRoute({
  //   name: 'people-id',
  //   path: '/people/:id?',
  //   component: () => import('@/pages/People/[id].vue'),
  // });
  router.addRoute({
    name: 'people-company-id',
    path: '/people/company/:id?',
    component: () => import('@/pages/people/company/[id].vue'),
  });
  router.addRoute({
    name: 'people-college-id',
    path: '/people/college/:id?',
    component: () => import('@/pages/people/college/[id].vue'),
  });
});