import { default as _91_46_46_46slug_93pg51Jx3PpjMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/[...slug].vue?macro=true";
import { default as Aboutw9Y8DrZCyMMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/About.vue?macro=true";
import { default as BrandY9hA4dQFTlMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/Brand.vue?macro=true";
import { default as BusinessxMRKOR3ToyMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/Business.vue?macro=true";
import { default as Contact4mjm9IoZlqMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/Contact.vue?macro=true";
import { default as Copy5wnVJEH9MhMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/Copy.vue?macro=true";
import { default as DashboardRrI0whOD5bMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/Dashboard.vue?macro=true";
import { default as FeedbacklRyfN53b5xMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/Feedback.vue?macro=true";
import { default as Forgetwes1okJH1cMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/Forget.vue?macro=true";
import { default as FundraisingL0RiwrPqdKMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/Fundraising.vue?macro=true";
import { default as indexc8Mj6mwLNcMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/index.vue?macro=true";
import { default as _91id_93TdQRkLvyLSMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/investors/detail/[id].vue?macro=true";
import { default as indexZ3ZzwOeLTeMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/investors/index.vue?macro=true";
import { default as indexktGaJfPL8eMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/login/index.vue?macro=true";
import { default as _91id_93XTAELO8uYTMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/people/[id].vue?macro=true";
import { default as _91id_93rvpuyYzi1BMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/people/college/[id].vue?macro=true";
import { default as _91id_93yO1MDJZjOKMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/people/company/[id].vue?macro=true";
import { default as index0NElnyjksSMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/people/index.vue?macro=true";
import { default as PrivacyIUkXD2GvoOMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/Privacy.vue?macro=true";
import { default as FavoriteslwFU4bgI8sMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/Profile/Favorites.vue?macro=true";
import { default as Passwordcaq3KMkGy7Meta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/Profile/Password.vue?macro=true";
import { default as Personal4eYKHJfFT0Meta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/Profile/Personal.vue?macro=true";
import { default as SubmitSRYnasxJGTMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/Profile/Submit.vue?macro=true";
import { default as ProfileG5KH98GRTFMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/Profile.vue?macro=true";
import { default as _91id_93hSfiJHaj80Meta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/projects/detail/[id].vue?macro=true";
import { default as indexNnR8s7R0tkMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/projects/index.vue?macro=true";
import { default as SubmitrJrscPSNyeMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/projects/Submit.vue?macro=true";
import { default as SearchHJISBVllMiMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/Search.vue?macro=true";
import { default as TermsIihnDlRqViMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/Terms.vue?macro=true";
import { default as Tools1GtEbfCePsMeta } from "/Users/lizhenjing/workspace/rootlist-fe/pages/Tools.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93pg51Jx3PpjMeta?.name ?? "slug",
    path: _91_46_46_46slug_93pg51Jx3PpjMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93pg51Jx3PpjMeta || {},
    alias: _91_46_46_46slug_93pg51Jx3PpjMeta?.alias || [],
    redirect: _91_46_46_46slug_93pg51Jx3PpjMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: Aboutw9Y8DrZCyMMeta?.name ?? "About",
    path: Aboutw9Y8DrZCyMMeta?.path ?? "/About",
    meta: Aboutw9Y8DrZCyMMeta || {},
    alias: Aboutw9Y8DrZCyMMeta?.alias || [],
    redirect: Aboutw9Y8DrZCyMMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/About.vue").then(m => m.default || m)
  },
  {
    name: BrandY9hA4dQFTlMeta?.name ?? "Brand",
    path: BrandY9hA4dQFTlMeta?.path ?? "/Brand",
    meta: BrandY9hA4dQFTlMeta || {},
    alias: BrandY9hA4dQFTlMeta?.alias || [],
    redirect: BrandY9hA4dQFTlMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/Brand.vue").then(m => m.default || m)
  },
  {
    name: BusinessxMRKOR3ToyMeta?.name ?? "Business",
    path: BusinessxMRKOR3ToyMeta?.path ?? "/Business",
    meta: BusinessxMRKOR3ToyMeta || {},
    alias: BusinessxMRKOR3ToyMeta?.alias || [],
    redirect: BusinessxMRKOR3ToyMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/Business.vue").then(m => m.default || m)
  },
  {
    name: Contact4mjm9IoZlqMeta?.name ?? "Contact",
    path: Contact4mjm9IoZlqMeta?.path ?? "/Contact",
    meta: Contact4mjm9IoZlqMeta || {},
    alias: Contact4mjm9IoZlqMeta?.alias || [],
    redirect: Contact4mjm9IoZlqMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/Contact.vue").then(m => m.default || m)
  },
  {
    name: Copy5wnVJEH9MhMeta?.name ?? "Copy",
    path: Copy5wnVJEH9MhMeta?.path ?? "/Copy",
    meta: Copy5wnVJEH9MhMeta || {},
    alias: Copy5wnVJEH9MhMeta?.alias || [],
    redirect: Copy5wnVJEH9MhMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/Copy.vue").then(m => m.default || m)
  },
  {
    name: DashboardRrI0whOD5bMeta?.name ?? "Dashboard",
    path: DashboardRrI0whOD5bMeta?.path ?? "/Dashboard",
    meta: DashboardRrI0whOD5bMeta || {},
    alias: DashboardRrI0whOD5bMeta?.alias || [],
    redirect: DashboardRrI0whOD5bMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/Dashboard.vue").then(m => m.default || m)
  },
  {
    name: FeedbacklRyfN53b5xMeta?.name ?? "Feedback",
    path: FeedbacklRyfN53b5xMeta?.path ?? "/Feedback",
    meta: FeedbacklRyfN53b5xMeta || {},
    alias: FeedbacklRyfN53b5xMeta?.alias || [],
    redirect: FeedbacklRyfN53b5xMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/Feedback.vue").then(m => m.default || m)
  },
  {
    name: Forgetwes1okJH1cMeta?.name ?? "Forget",
    path: Forgetwes1okJH1cMeta?.path ?? "/Forget",
    meta: Forgetwes1okJH1cMeta || {},
    alias: Forgetwes1okJH1cMeta?.alias || [],
    redirect: Forgetwes1okJH1cMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/Forget.vue").then(m => m.default || m)
  },
  {
    name: FundraisingL0RiwrPqdKMeta?.name ?? "Fundraising",
    path: FundraisingL0RiwrPqdKMeta?.path ?? "/Fundraising",
    meta: FundraisingL0RiwrPqdKMeta || {},
    alias: FundraisingL0RiwrPqdKMeta?.alias || [],
    redirect: FundraisingL0RiwrPqdKMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/Fundraising.vue").then(m => m.default || m)
  },
  {
    name: indexc8Mj6mwLNcMeta?.name ?? "index",
    path: indexc8Mj6mwLNcMeta?.path ?? "/",
    meta: indexc8Mj6mwLNcMeta || {},
    alias: indexc8Mj6mwLNcMeta?.alias || [],
    redirect: indexc8Mj6mwLNcMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93TdQRkLvyLSMeta?.name ?? "investors-detail-id",
    path: _91id_93TdQRkLvyLSMeta?.path ?? "/investors/detail/:id()",
    meta: _91id_93TdQRkLvyLSMeta || {},
    alias: _91id_93TdQRkLvyLSMeta?.alias || [],
    redirect: _91id_93TdQRkLvyLSMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/investors/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexZ3ZzwOeLTeMeta?.name ?? "investors",
    path: indexZ3ZzwOeLTeMeta?.path ?? "/investors",
    meta: indexZ3ZzwOeLTeMeta || {},
    alias: indexZ3ZzwOeLTeMeta?.alias || [],
    redirect: indexZ3ZzwOeLTeMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/investors/index.vue").then(m => m.default || m)
  },
  {
    name: indexktGaJfPL8eMeta?.name ?? "login",
    path: indexktGaJfPL8eMeta?.path ?? "/login",
    meta: indexktGaJfPL8eMeta || {},
    alias: indexktGaJfPL8eMeta?.alias || [],
    redirect: indexktGaJfPL8eMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93XTAELO8uYTMeta?.name ?? "people-id",
    path: _91id_93XTAELO8uYTMeta?.path ?? "/people/:id()",
    meta: _91id_93XTAELO8uYTMeta || {},
    alias: _91id_93XTAELO8uYTMeta?.alias || [],
    redirect: _91id_93XTAELO8uYTMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/people/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93rvpuyYzi1BMeta?.name ?? "people-college-id",
    path: _91id_93rvpuyYzi1BMeta?.path ?? "/people/college/:id()",
    meta: _91id_93rvpuyYzi1BMeta || {},
    alias: _91id_93rvpuyYzi1BMeta?.alias || [],
    redirect: _91id_93rvpuyYzi1BMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/people/college/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yO1MDJZjOKMeta?.name ?? "people-company-id",
    path: _91id_93yO1MDJZjOKMeta?.path ?? "/people/company/:id()",
    meta: _91id_93yO1MDJZjOKMeta || {},
    alias: _91id_93yO1MDJZjOKMeta?.alias || [],
    redirect: _91id_93yO1MDJZjOKMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/people/company/[id].vue").then(m => m.default || m)
  },
  {
    name: index0NElnyjksSMeta?.name ?? "people",
    path: index0NElnyjksSMeta?.path ?? "/people",
    meta: index0NElnyjksSMeta || {},
    alias: index0NElnyjksSMeta?.alias || [],
    redirect: index0NElnyjksSMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/people/index.vue").then(m => m.default || m)
  },
  {
    name: PrivacyIUkXD2GvoOMeta?.name ?? "Privacy",
    path: PrivacyIUkXD2GvoOMeta?.path ?? "/Privacy",
    meta: PrivacyIUkXD2GvoOMeta || {},
    alias: PrivacyIUkXD2GvoOMeta?.alias || [],
    redirect: PrivacyIUkXD2GvoOMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/Privacy.vue").then(m => m.default || m)
  },
  {
    name: ProfileG5KH98GRTFMeta?.name ?? "Profile",
    path: ProfileG5KH98GRTFMeta?.path ?? "/Profile",
    meta: ProfileG5KH98GRTFMeta || {},
    alias: ProfileG5KH98GRTFMeta?.alias || [],
    redirect: ProfileG5KH98GRTFMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/Profile.vue").then(m => m.default || m),
    children: [
  {
    name: FavoriteslwFU4bgI8sMeta?.name ?? "Profile-Favorites",
    path: FavoriteslwFU4bgI8sMeta?.path ?? "Favorites",
    meta: FavoriteslwFU4bgI8sMeta || {},
    alias: FavoriteslwFU4bgI8sMeta?.alias || [],
    redirect: FavoriteslwFU4bgI8sMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/Profile/Favorites.vue").then(m => m.default || m)
  },
  {
    name: Passwordcaq3KMkGy7Meta?.name ?? "Profile-Password",
    path: Passwordcaq3KMkGy7Meta?.path ?? "Password",
    meta: Passwordcaq3KMkGy7Meta || {},
    alias: Passwordcaq3KMkGy7Meta?.alias || [],
    redirect: Passwordcaq3KMkGy7Meta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/Profile/Password.vue").then(m => m.default || m)
  },
  {
    name: Personal4eYKHJfFT0Meta?.name ?? "Profile-Personal",
    path: Personal4eYKHJfFT0Meta?.path ?? "Personal",
    meta: Personal4eYKHJfFT0Meta || {},
    alias: Personal4eYKHJfFT0Meta?.alias || [],
    redirect: Personal4eYKHJfFT0Meta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/Profile/Personal.vue").then(m => m.default || m)
  },
  {
    name: SubmitSRYnasxJGTMeta?.name ?? "Profile-Submit",
    path: SubmitSRYnasxJGTMeta?.path ?? "Submit",
    meta: SubmitSRYnasxJGTMeta || {},
    alias: SubmitSRYnasxJGTMeta?.alias || [],
    redirect: SubmitSRYnasxJGTMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/Profile/Submit.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93hSfiJHaj80Meta?.name ?? "projects-detail-id",
    path: _91id_93hSfiJHaj80Meta?.path ?? "/projects/detail/:id()",
    meta: _91id_93hSfiJHaj80Meta || {},
    alias: _91id_93hSfiJHaj80Meta?.alias || [],
    redirect: _91id_93hSfiJHaj80Meta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/projects/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexNnR8s7R0tkMeta?.name ?? "projects",
    path: indexNnR8s7R0tkMeta?.path ?? "/projects",
    meta: indexNnR8s7R0tkMeta || {},
    alias: indexNnR8s7R0tkMeta?.alias || [],
    redirect: indexNnR8s7R0tkMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: SubmitrJrscPSNyeMeta?.name ?? "projects-Submit",
    path: SubmitrJrscPSNyeMeta?.path ?? "/projects/Submit",
    meta: SubmitrJrscPSNyeMeta || {},
    alias: SubmitrJrscPSNyeMeta?.alias || [],
    redirect: SubmitrJrscPSNyeMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/projects/Submit.vue").then(m => m.default || m)
  },
  {
    name: SearchHJISBVllMiMeta?.name ?? "Search",
    path: SearchHJISBVllMiMeta?.path ?? "/Search",
    meta: SearchHJISBVllMiMeta || {},
    alias: SearchHJISBVllMiMeta?.alias || [],
    redirect: SearchHJISBVllMiMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/Search.vue").then(m => m.default || m)
  },
  {
    name: TermsIihnDlRqViMeta?.name ?? "Terms",
    path: TermsIihnDlRqViMeta?.path ?? "/Terms",
    meta: TermsIihnDlRqViMeta || {},
    alias: TermsIihnDlRqViMeta?.alias || [],
    redirect: TermsIihnDlRqViMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/Terms.vue").then(m => m.default || m)
  },
  {
    name: Tools1GtEbfCePsMeta?.name ?? "Tools",
    path: Tools1GtEbfCePsMeta?.path ?? "/Tools",
    meta: Tools1GtEbfCePsMeta || {},
    alias: Tools1GtEbfCePsMeta?.alias || [],
    redirect: Tools1GtEbfCePsMeta?.redirect,
    component: () => import("/Users/lizhenjing/workspace/rootlist-fe/pages/Tools.vue").then(m => m.default || m)
  }
]