import { defineAppConfig } from "#imports";

export default defineAppConfig({
  title: 'Hello Nuxt',
  theme: {
    dark: true,
    colors: {
      primary: '#ff0000',
      $green: "#30b08f"
    }
  },
});