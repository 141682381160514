import revive_payload_client_4sVQNw7RlN from "/Users/lizhenjing/workspace/rootlist-fe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/lizhenjing/workspace/rootlist-fe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/lizhenjing/workspace/rootlist-fe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/lizhenjing/workspace/rootlist-fe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/lizhenjing/workspace/rootlist-fe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/lizhenjing/workspace/rootlist-fe/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/lizhenjing/workspace/rootlist-fe/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/lizhenjing/workspace/rootlist-fe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eskviOYyLt from "/Users/lizhenjing/workspace/rootlist-fe/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import plugin_8SbxDRbG6Y from "/Users/lizhenjing/workspace/rootlist-fe/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/Users/lizhenjing/workspace/rootlist-fe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "/Users/lizhenjing/workspace/rootlist-fe/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/Users/lizhenjing/workspace/rootlist-fe/.nuxt/element-plus-injection.plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/lizhenjing/workspace/rootlist-fe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ssr_plugin_B4ptqVdIfe from "/Users/lizhenjing/workspace/rootlist-fe/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import plugin_1UohGbtF8v from "/Users/lizhenjing/workspace/rootlist-fe/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import router_WMnTGednOQ from "/Users/lizhenjing/workspace/rootlist-fe/plugins/router.ts";
import echarts_client_0ubMsSY37x from "/Users/lizhenjing/workspace/rootlist-fe/plugins/echarts.client.ts";
import location_origin_client_1Pkeaqc2ON from "/Users/lizhenjing/workspace/rootlist-fe/plugins/location-origin.client.ts";
import location_href_client_QsvvfIMi2i from "/Users/lizhenjing/workspace/rootlist-fe/plugins/location-href.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_eskviOYyLt,
  plugin_8SbxDRbG6Y,
  i18n_yfWm7jX06p,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  chunk_reload_client_UciE0i6zes,
  ssr_plugin_B4ptqVdIfe,
  plugin_1UohGbtF8v,
  router_WMnTGednOQ,
  echarts_client_0ubMsSY37x,
  location_origin_client_1Pkeaqc2ON,
  location_href_client_QsvvfIMi2i
]