import validate from "/Users/lizhenjing/workspace/rootlist-fe/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/Users/lizhenjing/workspace/rootlist-fe/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/Users/lizhenjing/workspace/rootlist-fe/middleware/auth.ts"),
  "investor-detail-id": () => import("/Users/lizhenjing/workspace/rootlist-fe/middleware/investor-detail-id.ts"),
  "people-detail-id": () => import("/Users/lizhenjing/workspace/rootlist-fe/middleware/people-detail-id.ts"),
  "project-detail-id": () => import("/Users/lizhenjing/workspace/rootlist-fe/middleware/project-detail-id.ts")
}