
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "9586a0a0-c5f2-426f-88ee-3968e45c5c3b"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/Users/lizhenjing/workspace/rootlist-fe/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
